<template>
    <div class="full-height-layout fill">
        <router-view ref="routeview" class="fill full-height-layout"></router-view>
    </div>
</template>

<script>

export default {
    name: 'SupplierProducts',
    created () {
        this.$store.dispatch('products/getProductTemplates')
    },
}
</script>
